<template>
<div class="video" >
 <Header url="video" />
<div class="head">
      <div class="stitle">视频教程</div>
      <div class="stitle2">追求卓越 尽善尽美</div>
</div>
  <div class="vbox">
        <div class="w12">  
                <div class="hqueation">视频教程</div>
                <div class="hqueationzm">Video tutorial</div>
                <div class="clearfix" style="padding:10px 0">
                  <div class="item" v-for="(item,index) in list" :key="index">
                          <video :src="item.url" controls></video> 
                  </div>
              </div>
        </div>

 </div>




  <Footer />
  </div>
</template>

<script>
import '../../assets/css/index.css'
import './index.css'

import Header from '../../components/header'
import Footer from '../../components/footer'
import { gethelpVideo } from '../../request/api'
export default {
    name: 'Video',
    components: {
        Header,
        Footer,
    },
    data() {
      return {
          list:[]
      }
    },
    mounted(){
          this.getDate()
    },
    methods: {
      getDate(){
           let that =this;
           gethelpVideo().then(res=>{
               that.list=res.data
           })
      }
        
    }


}
</script>

